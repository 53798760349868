<template>
  <v-row class="d-flex">
    <v-col cols="8">
      <v-card>
        <v-card-title>
          <span class="font-weight-regular">Cordatus Services List </span>
          <v-divider class="ml-2" vertical></v-divider>
          <v-btn @click="initialize" icon class="mr-2">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Services"
            dense
            hide-details
            clearable
            outlined
            class="mr-2"
          ></v-text-field>
        </v-card-title>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="services"
              :search="search"
              :items-per-page="30"
              :footer-props="{
                'items-per-page-options': [10, 30, 50, -1],
              }"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span class="text-uppercase">{{ item.name }}</span>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  outlined
                  small
                  dark
                  label
                  :color="checkColor(item.status)"
                >
                  {{ item.status }}
                  <v-icon
                    v-if="item.status === 'Running'"
                    small
                    class="ml-1 rotate-icon"
                    >mdi-cog-outline</v-icon
                  >
                </v-chip>
              </template>

              <template v-slot:[`item.healthCheck`]="{ item }">
                <v-btn
                  :loading="item.loading"
                  small
                  text
                  :disabled="item.loading"
                  @click="healthCheck(item)"
                >
                  <v-icon class="ml-1" color="darken-2">mdi-update</v-icon>
                </v-btn>
              </template>

              <template v-slot:[`item.connection`]="{ item }">
                <v-btn
                  :disabled="!item.activeConnection"
                  small
                  text
                  @click="checkServiceConnection(item.name)"
                >
                  Connections <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="4">
      <connection-services-information
        :serviceName="selectedService.name"
      ></connection-services-information>
    </v-col>
  </v-row>
</template>

<script>
import { apiService } from '../../services/api.service'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { createUrl } from '@/utils/helpers'
import environment from '../../environment'
import ConnectionServicesInformation from '../../src/components/services/ConnectionServicesInformation.vue'
export default Vue.extend({
  components: { ConnectionServicesInformation },
  ...mapGetters(['userInfo']),
  data () {
    return {
      search: null,
      headers: [
        {
          text: 'Service Name',
          value: 'name',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center'
        },
        {
          text: 'Health Check',
          value: 'healthCheck',
          align: 'center'
        },
        {
          text: 'Connection Information',
          value: 'connection',
          align: 'center'
        }
      ],
      services: [
        {
          name: 'Backend Service',
          status: 'Unknown',
          baseUrl: createUrl(
            environment.backendSecure,
            environment.backendUrl,
            environment.backendPort,
            'health-check-backend-service'
          ),
          activeConnection: false,
          loading: false
        },
        {
          name: 'Api Service',
          status: 'Unknown',
          baseUrl: createUrl(
            environment.apiSecure,
            environment.apiUrl,
            environment.apiPort,
            'health-check-api-service'
          ),
          activeConnection: false,
          loading: false
        },
        {
          name: 'Postgres Service',
          status: 'Unknown',
          baseUrl: createUrl(
            environment.backendSecure,
            environment.backendUrl,
            environment.backendPort,
            'health-check-postgres-service'
          ),
          activeConnection: true,
          loading: false
        },
        {
          name: 'Signal Service',
          status: 'Unknown',
          baseUrl: createUrl(
            environment.signalServerSecure,
            environment.signalServerUrl,
            environment.signalServerPort,
            'peerjs/healthCheck'
          ),
          activeConnection: false,
          loading: false
        },
        {
          name: 'Local Tunnel Service',
          status: 'Unknown',
          baseUrl: createUrl(
            environment.tunnelServerSecure,
            environment.tunnelServerUrl,
            environment.tunnelServerPort,
            'api/status'
          ),
          activeConnection: false,
          loading: false
        }
      ],
      selectedService: {
        name: 'Postgres Service',
        status: ''
      }
    }
  },
  created () {
    this.initialize()
    this.checkServiceConnection(this.selectedService.name)
  },
  methods: {
    initialize () {
      this.services.forEach((item) => {
        this.healthCheck(item)
      })
    },
    async healthCheck (item) {
      item.loading = true
      await apiService
        .get(`${item.baseUrl}`)
        .then((response) => {
          item.status = 'Running'
          item.loading = false
        })
        .catch((error) => {
          item.loading = false
          item.status = 'Connection Lost'
          console.log('error', error)
        })
    },
    checkColor (status) {
      if (status === 'Running') {
        return 'green'
      } else if (status === 'Connection Lost') {
        return 'red'
      } else {
        return 'grey'
      }
    },
    checkServiceConnection (serviceName) {
      this.selectedService.name = serviceName
    }
  }
})
</script>

  <style scoped>
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
.rotate-icon {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
