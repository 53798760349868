<template>
  <v-card :loading="loading" outlined max-width="500">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">Service Connection Details</div>
        <v-list-item-title class="text-h5 mb-1"
          >{{ serviceName }} |
          <v-chip outlined small dark label :color="checkColor(status)">
            {{ status }}
            <v-icon v-if="status === 'Running'" small class="ml-1 rotate-icon"
              >mdi-cog-outline</v-icon
            >
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle
          >Shown the number of active connections in this
          service.</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list class="transparent">
      <v-list-item>
        <v-list-item-title>Service Name:</v-list-item-title>
        <v-list-item-subtitle class="text-right text-uppercase">
          {{ result.database_name }}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>Active Connections:</v-list-item-title>
        <v-list-item-subtitle class="text-right">
          {{ result.active_connections }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-btn :disabled="!isOnline" @click="checkActiveConnection" outlined>
        Check
      </v-btn>
    </v-card-actions>

    <v-snackbar text app v-model="connectionAlert" :timeout="timeout">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="connectionAlert = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { apiService } from '../../../services/api.service'
export default {
  data () {
    return {
      loading: false,
      connectionAlert: false,
      timeout: 3000,
      message: '',
      result: {
        database_name: 'Unknown',
        active_connections: 0
      },
      isOnline: false
    }
  },
  computed: {
    status () {
      if (this.isOnline) {
        return 'Running'
      } else {
        return 'Connection Lost'
      }
    }
  },
  created () {
    this.checkActiveConnection()
  },
  props: {
    serviceName: { type: String, default: 'Unknown' }
  },
  methods: {
    async checkActiveConnection () {
      this.loading = true
      this.connectionAlert = true
      await apiService
        .get('active-database-connections')
        .then((response) => {
          this.message = response.data.message
          this.result = response.data.data
          this.isOnline = true
        })
        .catch((error) => {
          this.result.database_name = 'Unknown'
          this.result.active_connections = 0
          this.isOnline = false
          console.log('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkColor (status) {
      if (status === 'Running') {
        return 'green'
      } else if (status === 'Connection Lost') {
        return 'red'
      } else {
        return 'grey'
      }
    }
  }
}
</script>

<style>
.rotate-icon {
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
